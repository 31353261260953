<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <div  class="row col-12 mb-3">
              <div class="col-12 text-muted">Cliente</div>
              <div class="col-12 ">{{ (task)?task.customer.name:'' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive">
                  <vuetable ref="vuetable"
                    :noDataTemplate="$t('noting to result')"
                    :api-url="apiEndpoint"
                    :fields="fieldsTable"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path="pagination"
                    :per-page="20"
                    :multi-sort="multiSort"
                    :append-params="filter"
                    multi-sort-key="shift"
                    :http-options="httpOptions"
                    track-by="id"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded"
                    @vuetable:load-error="handleLoadError"
                    @vuetable:cell-clicked="onRowClicked">
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                  :css="css.pagination"
                  @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TaskService from '@/services/TaskService'
import Vuetable, { VuetablePagination } from 'vuetable-2'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'WhatsAppRecipientList',
  data () {
    return {
      task: null,
      isLoading: false,
      fullPage: true,
      apiEndpoint: null,
      multiSort: true,
      activeFilterList: {},
      filter: {},
      sortOrder: [],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      fieldsTable: [
        {
          name: 'cad_pessoa_contato.nome',
          title: this.$i18n.t('form.taskWhatsapp.name'),
          sortField: 'tr.createdAt',
          formatter: (value) => value
        },
        {
          name: 'phone',
          title: this.$i18n.t('form.taskWhatsapp.phone'),
          sortField: 'tr.createdAt',
          formatter: (value) => value
        },
        {
          name: 'sent_at',
          title: this.$i18n.t('form.taskWhatsapp.sent_at'),
          sortField: 'tr.createdAt',
          formatter: (value) => this.formatDateTime(value)
        },
        {
          name: 'received_at',
          title: this.$i18n.t('form.taskWhatsapp.received_at'),
          sortField: 'tr.createdAt',
          formatter: (value) => this.formatDateTime(value)
        },
        {
          name: 'read_at',
          title: this.$i18n.t('form.taskWhatsapp.read_at'),
          sortField: 'tr.createdAt',
          formatter: (value) => this.formatDateTime(value)
        },
        {
          name: 'task_whatsapp_messages.0.received_at',
          title: this.$i18n.t('form.taskWhatsapp.answered'),
          sortField: 'tr.createdAt',
          formatter: (value) => this.formatDateTime(value)
        }
      ],
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    Loading,
    Vuetable,
    VuetablePagination
  },
  methods: {
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    onRowClicked (row) {
    },
    handleLoadError (response) {
    }
  },
  created () {
    this.isLoading = true
    let taskId = this.$route.params.id_schedule

    TaskService.getTask(taskId).then(task => {
      this.task = task.data
      this.apiEndpoint = process.env.VUE_APP_APIENDPOINT + 'v2/tarrifs/' + this.task.id + '/message/whatsapp'
      this.isLoading = false
    }).catch(() => {
      this.isLoading = false
    })
  }
}
</script>
